import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var myFooter = function myFooter() {
  return import("./common/myFooter");
};

export default {
  components: {
    myFooter: myFooter
  },
  data: function data() {
    return {
      card: null,
      collects: {}
    };
  },
  computed: {},
  watch: {},
  created: function created() {
    this.card = 1;
    this.getCollect();
  },
  mounted: function mounted() {},
  methods: {
    toUrl: function toUrl(url) {
      window.open(url);
    },
    changeFavorite: function changeFavorite(card) {
      this.card = card;

      if (card === 1) {
        if (this.$common.isEmpty(this.collects)) {
          this.getCollect();
        }
      }
    },
    getCollect: function getCollect() {
      var _this = this;

      this.$http.get(this.$constant.baseURL + "/webInfo/listCollect").then(function (res) {
        if (!_this.$common.isEmpty(res.data)) {
          _this.collects = res.data;
        }
      }).catch(function (error) {
        _this.$message({
          message: error.message,
          type: "error"
        });
      });
    }
  }
};